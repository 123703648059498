@mixin image_girl($withSize: true) {
  background-image: url(./girl.jpg);

  @supports (background-image: image-set(url(./girl.webp), url(./girl.jpg))) {
    background-image: image-set(url(./girl.webp), url(./girl.jpg));
  }

  @if $withSize {
    background-size: 640px 1136px;
  }
}

@mixin image_girl__1024miw($withSize: true) {
  background-image: url(./girl@1024miw.jpg);

  @supports (background-image: image-set(url(./girl@1024miw.webp), url(./girl@1024miw.jpg))) {
    background-image: image-set(url(./girl@1024miw.webp), url(./girl@1024miw.jpg));
  }

  @if $withSize {
    background-size: 2540px 2048px;
  }
}

@mixin image_girl__1280miw($withSize: true) {
  background-image: url(./girl@1280miw.jpg);

  @supports (background-image: image-set(url(./girl@1280miw.webp), url(./girl@1280miw.jpg))) {
    background-image: image-set(url(./girl@1280miw.webp), url(./girl@1280miw.jpg));
  }

  @if $withSize {
    background-size: 2880px 2160px;
  }
}

@mixin image_girl__2560miw($withSize: true) {
  background-image: url(./girl@2560miw.jpg);

  @supports (background-image: image-set(url(./girl@2560miw.webp), url(./girl@2560miw.jpg))) {
    background-image: image-set(url(./girl@2560miw.webp), url(./girl@2560miw.jpg));
  }

  @if $withSize {
    background-size: 7680px 3600px;
  }
}

@mixin image_girl__375miw($withSize: true) {
  background-image: url(./girl@375miw.jpg);

  @supports (background-image: image-set(url(./girl@375miw.webp), url(./girl@375miw.jpg))) {
    background-image: image-set(url(./girl@375miw.webp), url(./girl@375miw.jpg));
  }

  @if $withSize {
    background-size: 750px 1624px;
  }
}

@mixin image_girl__768miw($withSize: true) {
  background-image: url(./girl@768miw.jpg);

  @supports (background-image: image-set(url(./girl@768miw.webp), url(./girl@768miw.jpg))) {
    background-image: image-set(url(./girl@768miw.webp), url(./girl@768miw.jpg));
  }

  @if $withSize {
    background-size: 1536px 2048px;
  }
}
