@import 'apps/common/shared/styles/mixins';
@import './images/girl/girl.scss';

@keyframes descriptionWord {
  from {
    opacity: 0;
    transform: translateY(70px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-repeat: no-repeat;

  @include image_girl(false);
  background-position: 50% 50%;
  background-position: 50% 50%;

  @media (min-width: 375px) {
    @include image_girl__375miw(false);
    background-position: 100% 0%;
  }

  @media (min-width: 768px) {
    @include image_girl__768miw(false);
    background-position: 50% 50%;
  }

  @media (min-width: 1024px) {
    @include image_girl__1024miw(false);
    background-position: 50% 50%;
  }

  @media (min-width: 1280px) {
    @include image_girl__1280miw(false);
    background-position: 100% 0;
  }

  @media (min-width: 2560px) {
    @include image_girl__2560miw(false);
    background-position: 100% 0;
  }
}

.app__logo svg {
  height: 100%;
  width: 100%;
}

.app__logo {
  width: 846px;
  height: 165px;
  margin-top: 200px;
  margin-bottom: 116px;
  @media (max-width: 2559px) {
    width: 351px;
    height: 69px;
    margin-top: 100px;
    margin-bottom: 48px;
  }
  @media (max-width: 1180px) {
    width: 245px;
    height: 48px;
  }
  @media (max-width: 767px) {
    width: 178px;
    height: 35px;
    margin-top: 18px;
    margin-bottom: 24px;
  }
}

.app__container {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  width: 1832px;
  margin: 0 auto;
  @media (max-width: 2559px) {
    width: 850px;
  }
  @media (max-width: 1180px) {
    width: 530px;
  }
  @media (max-width: 767px) {
    padding: 0 29px;
    width: 100%;
    margin: 0;
  }
}

.app__container_footer {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 205px;
  max-width: 1832px;
  @media (max-width: 2559px) {
    max-width: 850px;
    height: 70px;
    margin: 0 auto;
  }
  @media (max-width: 1180px) {
    max-width: 530px;
    margin: 0 auto;
  }
  @media (max-width: 767px) {
    max-width: 100%;
    height: 40px;
  }
}

.app__firstscreen {
  margin: 0;
}

.app__title {
  @include font(106px, 500, 120%, #ffffff);
  @media (max-width: 2559px) {
    @include font(44px, 500, 120%, #ffffff);
  }
  @media (max-width: 1180px) {
    @include font(36px, 500, 120%, #ffffff);
  }
  @media (max-width: 767px) {
    @include font(28px, 500, 33.6px, #ffffff);
  }
  @include for-mobile-s {
    @include font(20px, 500, 120%, #ffffff);
  }
}

.app__title span {
  display: inline-block;
  opacity: 0;
  animation: descriptionWord 0.5s ease forwards;

  @for $i from 1 through 20 {
    &:nth-child(#{$i}) {
      animation-delay: #{$i * 0.1}s;
    }
  }
}

.app__linksContainer {
  display: none;
  flex-wrap: wrap;
  column-gap: 11px;
  row-gap: 11px;
  padding-bottom: 75px;
  justify-content: center;
  @media (max-width: 767px) {
    display: flex;
    max-width: 464px;
    margin: 0 auto;
  }
}

.app__footer {
  display: flex;
  @media (max-width: 767px) {
    background: rgba(21, 21, 21, 0.6);
  }
}

.app__footerLinks {
  display: flex;
}

.app__footerLink {
  @include font(36px, 400, 45px, #ffffff);
  margin-right: 80px;
  @media (max-width: 2559px) {
    @include font(14px, 400, 22px, #ffffff);
    margin-right: 40px;
  }
  @media (max-width: 767px) {
    @include font(12px, 400, 15px, #ffffff);
    margin-right: 12px;
  }
  @media (max-width: 375px) {
    @include font(10px, 400, 15px, #ffffff);
    margin-right: 12px;
  }
}

.app__copyright {
  @include font(36px, 400, 45px, #ffffff);

  @media (max-width: 2559px) {
    @include font(14px, 400, 22px, #ffffff);
    margin-right: -60px;
  }
  @media (max-width: 767px) {
    margin-right: 0;
    opacity: 0.5;
    @include font(12px, 400, 15px, #ffffff);
  }
}

.app__link {
  height: 34px;
  @media (min-width: 375px) {
    height: 48px;
  }
}

.app__link svg {
  height: 34px;
  @media (min-width: 375px) {
    height: 48px;
  }
}

.app_qr svg {
  margin-top: 225px;
  width: 410px;
  height: 410px;
  @media (max-width: 2559px) {
    width: 128px;
    height: 128px;
    margin-top: 76px;
  }
  @media (max-width: 767px) {
    display: none;
  }
}
